<template>
  <div class="specsContainer" v-if="specs">
    <h2>{{ $t('Ürün Özellikleri') }}</h2>
    <div v-for="item in specs" :key="item.degerId" class="row">
      <div>{{ item.ozellikAdi }}</div><div>{{ item.degerAdi }}</div>
    </div>
  </div>
</template>

<style scoped lang="scss">
h2 {
  font-size: 16px;
  margin-bottom: 12px;
}
.specsContainer {
  padding:12px;background:#fff;border:2px solid #f1f1f1;
  border-radius:8px;
  margin-bottom: 20px;
  .row {
    display:grid; grid-template-columns:120px auto;font-size:12px;padding-top:6px;padding-bottom:6px;padding-left:6px
  }
  .row:nth-child(even) {
    background:#f1f1f1;
  }
}
</style>

<script>
export default ({
  props: ['specs']
})
</script>
