<template>
  <div class="card">
    <router-link :to="{ name: 'Page', params: { id: info.id + '-' + $fnc.seo(info.title), parent: 0 } }">
      <img v-if="info.src" :alt="info.title" :src="cdn + info.src">
      <div class="text">
        <h3>{{ info.title }}</h3>
        <p>{{ info.desc }}</p>
      </div>
    </router-link>
  </div>
</template>

<style scoped lang="scss">
.card {
  padding: 0px;
  background:#fff;
  .text {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 15px;
  }
  img {
    width: 100%;
    height:287px;
  }
  h3 {
    margin-bottom: 4px;
    font-size: 18px;
    font-weight: 600;
  }
  p {
    font-size: 14px;
    line-height: 32px;
  }
  a {
    color: #000;
  }
  a:hover {
    color: var(--color1);
  }
}
</style>

<script>
export default ({
  props: ['info'],
  data () {
    return {
      cdn: this.$store.state.fileCdn
    }
  }
})
</script>
