<template>
  <div>
    <div style="display:grid; grid-template-columns: 1fr; gap:30px;margin-bottom:20px">
      <div class="card">
        <p><b>{{ user.kullanicilarAdi }}</b></p>
        <p>{{ user.kullanicilarEmail }}</p>
        <p>{{ $fnc.phone(user.kullanicilarGsm) }}</p>
        <button @click="showProfileModal"><i class="fa fa-edit"></i> {{ $t('Düzenle') }}</button>
      </div>
    </div>
    <div style="margin-top:60px">
      <h2 style="margin-bottom:15px">{{ $t('Adres Bilgilerim') }}</h2>
      <div class="grid2">
        <div v-for="item in addresses" :key="item.adresId" class="card">
          <p><b>{{ $t(item.adresTip) }}</b></p>
          <p>{{ item.adres }}</p>
          <p>{{ item.ilce }} / {{ item.il }}</p>
          <p>{{ $fnc.phone(item.tel) }}</p>
          <p>{{ item.tc }}</p>
        </div>
      </div>
    </div>
    <GlassModal v-if="profileModal" @close="showProfileModal">
      <template v-slot:title>{{ $t('Üyelik bilgilerini düzenleyin') }}</template>
      <template v-slot:body>
        <div v-if="savingProfile" style="display:grid;place-items:center">
          <img src="@/assets/loading-pulse.svg">
        </div>
        <form method="dialog" autocomplete="never" v-else>
          <p class="formError" v-if="profileError">{{ profileError }}</p>
          <div id="registername">
            <label>{{ $t('Adınız Soyadınız') }}</label>
            <input @keypress.enter="saveProfileInfo" v-model="user.kullanicilarAdi" type="text" autocomplete="never" :placeholder="$t('Adınız Soyadınız')">
          </div>
          <div id="registergsm">
            <label>{{ $t('GSM No') }}</label>
            <input @keypress.enter="saveProfileInfo" v-model="user.kullanicilarGsm" type="text" autocomplete="never" :placeholder="$t('GSM No')">
          </div>
          <div style="display:flex">
            <button @click="saveProfileInfo"><i class="fa fa-save"></i> {{ $t('Güncelle') }}</button>
          </div>
        </form>
      </template>
    </GlassModal>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  props: ['info'],
  data () {
    return {
      cdn: this.$store.state.mediaCdn,
      showFileUploader: false,
      addresses: this.info.user.addresses,
      user: this.info.user.user,
      profileModal: false,
      formError: null,
      savingProfile: false,
      profileError: null
    }
  },
  methods: {
    saveProfileInfo: async function () {
      this.savingProfile = true
      const payload = {
        user: this.$store.state.user,
        userName: this.user.kullanicilarAdi,
        userGsm: this.user.kullanicilarGsm
      }
      await axios.post('/api/SaveProfileInfo.php', payload).then(response => {
        const data = response.data
        const error = data.error
        if (error !== 'none') {
          this.profileError = error
        }
        setTimeout(() => {
          this.savingProfile = false
          if (error === 'none') {
            this.$parent.getInfo()
          }
        }, 1000)
      })
    },
    openModal: function (stockId) {
      this.showFileUploader = true
      this.stockId = stockId
      setTimeout(() => {
        this.closeModal()
      }, 1500)
    },
    closeModal: function () {
      this.showFileUploader = false
      this.stockId = null
    },
    showProfileModal: function () {
      this.profileModal = !this.profileModal
    }
  }
}
</script>

<style scoped lang="scss">
.formError {
  width:calc(100% - 24px);
  padding:8px;
  font-size: 13px;
  border:2px dashed orangered;
  border-radius: 8px;
  margin-bottom: 12px;
}
.grid2 {
  display:grid; grid-template-columns: 1fr 1fr; gap:30px;margin-bottom:20px
}
.card {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  p {
    margin-bottom: 8px;
  }
}
button {
  border-radius:3px;
  border: 1px solid var(--color2);
  outline: none;
  background: var(--color2);
  color: #fff;
  padding: 7px 15px 7px 0px;
  transition: all 0.1s ease-in;
  display: grid;
  grid-template-columns: 40px auto;
  align-items: center;
  text-align: left;
  font-size: 12px;
  i {
    text-align: center;
    font-size: 13px;
  }
}
button:hover {
  background:#333;
  border-color: #333;
  color: #fff;
}
form {
  width:440px;
  div {
    display: grid;
    grid-template-columns: 1fr;
    gap:3px;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 18px;
    width:94%;
    label {
      padding-left:2px;
    }
    input {
      border-radius: 5px;
      border: 1px solid #ccc;
      padding:12px;
      width:100%;
      margin: auto;
    }
  }
}
label {
  font-size: 11px;
}
label a {
  font-size: 11px;
  text-decoration: underline;
  cursor: pointer;
}
label a:hover {
  color:orangered;
}
@media screen and (max-width: 768px) {
  .grid2 {
    grid-template-columns:1fr;
  }
  form {
    width:320px;
  }
}
</style>
