<template>
  <span style="font-size:3px"></span>
</template>

<script>
export default ({
  props: ['jsonld'],
  jsonld () {
    const jsonld = this.jsonld
    return {
      jsonld
    }
  }
})
</script>
