<template>
  <div>
    <div>
      <div v-if="promo" class="promo" :style="'background:url(' + fileCdn + promo.image + ') no-repeat center;background-color: ' + promo.color + ';'">
        <router-link v-if="promo.url" :to="promo.url" style="width:100%;height:100%;" :title="promo.title"></router-link>
      </div>
      <div style="height:70px">
        <div class="headerContainer">
          <header>
            <div>
              <button v-if="hasDrawer" @click="showBottomMenu" :disabled="overlay" title="Menü">
                <i class="fa fa-bars"></i>
              </button>
            </div>
            <div>
              <router-link :to="{ name: 'Index' }" class="logo" :title="$t('Anasayfa')"></router-link>
            </div>
            <div>
              <button @click="showSearch" :disabled="overlay" title="Ara">
                <i class="fa fa-search"></i>
              </button>
              <a @click="showLanguageSelector" style="text-transform:uppercase;margin-left:8px" ><i class="fa fa-globe-americas"></i></a>
              <GlassModal v-if="langSelector" @close="showLanguageSelector">
                <template v-slot:title>{{ $t('Dil seçiminizi yapın') }}</template>
                <template v-slot:body >
                  <ul class="languageSelector">
                    <li v-for="l in langs" :key="l.v">
                      <button :class="{ 'activeButton': l.v === $i18n.locale }" @click="changeLocale(l.v)">{{ l.title }}</button>
                    </li>
                  </ul>
                </template>
              </GlassModal>
            </div>
            <div class="phoneHolder">
              <a :href="'tel:' + $store.state.info.companyInfo.PAGE_PHONE" style="color:white"><i class="fa fa-phone-alt" style="font-size:18px"></i></a>
            </div>
          </header>
        </div>
      </div>
    </div>
    <div class="sidenav" :class="{ 'sidenavOpen': subMenu }">
      <a @click="hideMenu" class="closebtn">×</a>
      <router-link @click="hideMenu" v-for="item in info.menu[0].subs" :key="item.id" :to="{ name: 'Products', params: { id: item.id + '-' + $fnc.seo(item.title), top: item.parent_id }}">{{ item.title }}</router-link>
    </div>
    <div v-if="searchModal" class="searchModal">
      <div class="input">
        <i class="fa fa-search"></i>
        <input @keydown.enter="makeSearch" id="mobileSearcher" v-model.trim="searchField" type="text" :placeholder="$t('Ürünlerde Arayın')">
        <button @click="makeSearch">{{ $t('Ara') }}</button>
      </div>
    </div>
    <div v-if="searchModal">
      <div @mouseover="hideSearch" class="overlay" :style="'height:' + ($fnc.getHeight() - 200) + 'px;'"></div>
    </div>
    <div v-if="subMenu">
      <div @mouseover="hideMenu" class="overlay" :style="'height:' + ($fnc.getHeight() - 200) + 'px;'"></div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.phoneHolder {
  position:fixed; bottom:70px; right:15px;
  background:#38a6ff;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.15);
  color:white;
  width:40px;
  height:40px;
  border-radius:20px;
  display:grid;
  place-items: center;
}
.promo {
  height:40px;
  font-family:arial;font-weight:bold; color:#fff; text-align:center;font-size:30px;display:grid;place-items:center;
  background-size: cover !important;
}
.overlay {
  background:rgba(0,0,0,0.7);width:100%;height:100%;position:absolute;z-index:12; top:0;
  overscroll-behavior: contain;
}
#mobileSearcher:focus {
  box-shadow: unset;
}
.searchModal {
  height: 60px;
  width: (100vw);
  position: fixed;
  z-index: 120;
  top:56px;
  left: 0;
  transition: all 0.1s ease-in;
  .input {
    padding: 10px;
    width:calc(100% - 30px);
    margin-left:15px;
    border-radius: 8px;
    border: 2px solid var(--black2);
    background:#fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  i {
    width:24px;
  }
  button {
    width:60px;
  }
  input{
    width:100%;
    outline: none;
    border: none;
    background: none;
  }
}
.sidenav {
  overscroll-behavior: contain;
  height: 100%;
  width: 320px;
  position: fixed;
  z-index: 103;
  top: 0;
  left: -320px;
  background-color:#222529;
  overflow-x: hidden;
  transition: 0.3s ease-out;
  padding-top: 60px;
  font-family: var(--font2);
  a {
    overscroll-behavior: contain;
    padding: 8px 8px 8px 26px;
    margin-bottom: 8px;
    text-decoration: none;
    font-size: 22px;
    color: #c2c2c2;
    display: block;
    transition: 0.3s;
    outline:none;
  }
  a:hover {
    color: #f1f1f1;
  }
  .closebtn {
    overscroll-behavior: contain;
    position: absolute;
    top: 5px;
    right: 15px;
    font-size: 36px;
    margin-left: 50px;
  }
}
.sidenavOpen {
  left: 0;
}
.headerContainer {
  border-bottom: 1px solid #f1f1f1;
  box-shadow: 0px 2px 10px rgba(0,0,0,0.15);
  z-index: 10;
  position: absolute;
  width: calc(100vw);
  background: #fff;
}
header {
  display: grid;
  grid-template-columns: 80px auto 80px;
  align-items: center;
  justify-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  div {
    width: 100%;
    height: 100%;
    button {
      z-index:1;
      border:none;background:none;width:50%; height:100%;
      outline: none;
      i {
        font-size: 16px;
      }
    }
  }
}
.logo {
  margin:auto;
  width: 130px;
  height: 50px;
  display: grid;
  align-items:center;
  background: url('../assets/logo.svg') center no-repeat;
  background-size: contain;
}
.languageSelector {
  width:calc(300px);
  margin: unset;
  padding-right:10px;
  list-style:none;
  display:grid;
  justify-items: center;
  li {
    padding: 0px;
    margin: 0px;
    margin-bottom: 20px;
    width:calc(100%);
  }
  .activeButton {
    background:var(--color1);
  }
  button {
    width:100%;
    border:none;
    background:#555;
    color:white;
    padding:9px;
    border-radius:8px;
    transition: all 0.14s ease-in;
  }
  button:hover {
    background:var(--color2);
  }
}
</style>

<script>
export default {
  props: ['info'],
  data () {
    return {
      subMenu: false,
      overlay: false,
      searchModal: false,
      openMenuId: '',
      menu: this.info.menu[0].subs,
      promo: this.$store.state.info.topPromoMobile,
      fileCdn: this.$store.state.fileCdn,
      hasDrawer: true,
      searchField: '',
      langSelector: false,
      langs: [{ title: 'Türkçe', v: 'tr' }, { title: 'English', v: 'en' }]
    }
  },
  computed: {
    cart () {
      return this.$store.state.cart
    }
  },
  watch: {
    $route (to, from) {
      const h = (this.$route.hash)
      if (h === '#1') {
        // this.subMenu = true
      } else {
        // this.subMenu = false
      }
    }
  },
  methods: {
    changeLocale: function (l) {
      this.$i18n.locale = l
      localStorage.setItem('lang', l)
      this.langSelector = false
      setTimeout(() => {
        window.location.href = '/' + l + '/'
      }, 100)
    },
    showLanguageSelector: function () {
      this.langSelector = !this.langSelector
    },
    makeSearch: function () {
      const s = this.searchField.trim()
      if (s.length > 1) {
        this.$router.push({ name: 'Search', params: { search: s } })
      }
    },
    showMenu: function () {
      this.overlay = true
      this.subMenu = true
      document.querySelector('body').style.overflow = 'hidden'
    },
    hideMenu: function () {
      this.overlay = false
      this.subMenu = false
      document.querySelector('body').style.overflow = 'auto'
    },
    showBottomMenu: function () {
      this.showMenu()
      // this.$router.push('/c/4/4-tum-urunler')
      /*
      this.hideMenu()
      setTimeout(() => {
        this.$store.dispatch('setBottomMenu', !this.$store.bottomMenu)
      }, 10)
      */
    },
    showSearch: function () {
      this.searchModal = true
      this.overlay = true
      document.querySelector('body').style.overflow = 'hidden'
      setTimeout(() => {
        document.querySelector('#mobileSearcher').focus()
      }, 100)
    },
    hideSearch: function () {
      this.searchModal = false
      this.overlay = false
      document.querySelector('body').style.overflow = 'auto'
    },
    getCart: function () {
      this.$store.dispatch('setCart', 10)
    }
  }
}
</script>
