<template>
  <div v-if="info">
    <div class="videoContainer">
      <video class="video" autoplay muted loop>
        <source :src="cdn + info.video" type="video/mp4">
      </video>
    </div>
    <div class="content">
      <div>
        <h1>{{ info.title }}</h1>
        <p>{{ info.text }}</p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.videoContainer {
  margin:auto;
  width:100%;
  height: calc(100vh - 180px);
  overflow:hidden;
  .video {
    width: 100%;
    margin-top:-150px;
  }
}
.content {
  background: rgba(0, 0, 0, 0.45);
  position: absolute;
  margin-top: calc(-100vh + 180px);
  height: calc(100vh - 180px);
  color: #f1f1f1;
  width: 100%;
  padding: 20px;
  div {
    width:500px; margin:auto;display:grid;justify-items:center;margin-top:18vh
  }
  h1 {
    font-size: 36px;
    margin-bottom: 30px;
    text-transform:uppercase
  }
  p {
    text-align: center;
    font-size: 19px;
    line-height: 30px;
  }
}
@media screen and (max-width:768px) {
  .videoContainer {
    margin:auto;
    width:100%;
    height: 220px;
    overflow:hidden;
    .video {
      width: 100%;
      margin-top: 0px;
    }
  }
  .content {
    margin-top:-220px;
    height: 220px;
    width:100%;
    display: grid;
    place-items: center;
    div {
      margin: auto;
      width:100%;
    }
    h1 {
      font-size: 20px;
      margin-bottom: 12px;
    }
    p {
      font-size: 14px;
      line-height: 22px;
    }
  }
}
</style>

<script>
export default {
  props: ['info'],
  data () {
    return {
      cdn: this.$store.state.fileCdn
    }
  }
}
</script>
