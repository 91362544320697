<template>
  <FixedNavbar :info="info" v-if="$store.state.mobileWidth > $fnc.getWidth()" />
</template>

<script>
import FixedNavbar from '@/components/FixedNavbar.vue'
export default {
  components: { FixedNavbar },
  props: ['info']
}
</script>
