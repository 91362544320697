<template>
  <div class="mt">
    <div v-for="item in info" :key="item.id">
      <Flex v-if="item.component === 'flex'" :info="item" />
      <Grid v-if="item.component === 'grid'" :info="item" />
      <Collection v-if="item.component === 'collection'" :info="item" />
    </div>
  </div>
</template>

<script>
import Flex from '../home-components/Flex.vue'
import Grid from '../home-components/Grid.vue'
import Collection from '../home-components/Collection.vue'

export default {
  components: {
    Flex,
    Grid,
    Collection
  },
  props: ['info'],
  data () {
    return {
      home: 'yes Mofos so'
    }
  }
}
</script>
