import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    info: {},
    home: {},
    cart: 0,
    user: {
      sId: false,
      email: false,
      userId: false,
      userName: false
    },
    bottomMenu: false,
    mobileWidth: 769,
    sort: 'new',
    showPriceSorter: false,
    pageSize: 24,
    priceWithVat: false,
    fileCdn: 'https://www.kareiki.com/eforisi/files/',
    mediaCdn: 'https://www.kareiki.com/eforisi/media/',
    baseUrl: 'https://eforisi.com'
  },
  mutations: {
    setInfo (state, info) {
      state.info = info
    },
    setUser (state, user) {
      state.user = user
    },
    setHome (state, home) {
      state.home = home
    },
    setCart (state, cart) {
      state.cart = cart
    },
    setSort (state, sort) {
      state.sort = sort
    },
    setBottomMenu (state, bottomMenu) {
      state.bottomMenu = bottomMenu
    }
  },
  actions: {
    setInfo (context, info) {
      context.commit('setInfo', info)
    },
    setUser (context, user) {
      context.commit('setUser', user)
    },
    setHome (context, home) {
      context.commit('setHome', home)
    },
    setCart (context, cart) {
      context.commit('setCart', cart)
    },
    setSort (context, sort) {
      context.commit('setSort', sort)
    },
    setBottomMenu (context, bottomMenu) {
      context.commit('setBottomMenu', bottomMenu)
    }
  }
})
