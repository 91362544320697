<template>
  <div>
    <div v-if="$store.state.mobileWidth > $fnc.getWidth()">
      <MobileHeader :info="info" />
    </div>
    <div v-else>
      <div v-if="info.companyInfo" style="background:#202020;border-bottom:1px solid #fff;">
        <div v-if="promo" class="promo" :style="'background:url(' + fileCdn + promo.image + ') no-repeat center;background-color: ' + promo.color + ';'">
          <router-link v-if="promo.url" :to="promo.url" style="width:100%;height:100%;" :title="promo.title"></router-link>
        </div>
        <header @mouseover="hideMenu" class="container">
          <div class="row1"></div>
          <div class="row2">
            <router-link :to="{ name: 'Index' }" class="logo" :title="$t('Anasayfa')"></router-link>
            <div class="topMenu">
              <router-link v-for="item in info.menu" :key="item.id" :to="{ name: 'Products', params: { id: item.id + '-' + $fnc.seo(item.title), top: item.id }}">{{ item.title }}</router-link>
              <router-link v-for="item in info.ustMenu" :key="item.id" :to="{ name: 'Page', params: { id: item.id + '-' + $fnc.seo(item.title), parent: 0 }}">{{ item.title }}</router-link>
            </div>
            <div class="cartZone">
              <div class="searchContainer">
                <div class="searchBox2">
                  <input @keydown.enter="makeSearch" type="text" v-model.trim="searchField" :placeholder="$t('Ürünlerde Arayın')">
                  <button @click="makeSearch">{{ $t('Ara') }}</button>
                </div>
              </div>
              <router-link v-if="!$store.state.user.userId" :to="{ name: 'Login' }"><i class="fa fa-user"></i> {{ $t('Üye Ol / Giriş Yap') }}</router-link>
              <router-link v-else :to="{ name: 'MyAccountOrders' }"><i class="fa fa-user"></i> {{ $t('Hesabım') }}</router-link>
              <router-link :to="{ name: 'Cart' }">
                <i class="fa fa-shopping-cart"></i>
                <span v-if="$store.state.cart" >
                  <span class="cartCount">{{ $store.state.cart }}</span>
                  <span style="margin-left:14px">{{ $t('Sepetim') }}</span>
                </span>
                <span v-else> {{ $t('Sepetim') }}</span>
              </router-link>
              <router-link :to="{ name: 'Contacts' }"><i class="fa fa-phone-alt"></i> {{ $t('İletişim') }}</router-link>
              <a @click="showLanguageSelector"><i class="fa fa-globe-americas"></i> {{ $i18n.locale }}</a>
            </div>
          </div>
          <div></div>
        </header>
      </div>
      <div v-else style="min-height:160px"></div>
    </div>
    <GlassModal v-if="langSelector" @close="showLanguageSelector">
      <template v-slot:title>{{ $t('Dil seçiminizi yapın') }}</template>
      <template v-slot:body>
        <ul class="languageSelector">
          <li v-for="l in langs" :key="l.v">
            <button :class="{ 'activeButton': l.v === $i18n.locale }" @click="changeLocale(l.v)">{{ l.title }}</button>
          </li>
        </ul>
      </template>
    </GlassModal>
  </div>
</template>

<script>
import MobileHeader from '@/components/MobileHeader'
export default {
  components: { MobileHeader },
  props: ['info'],
  data () {
    return {
      subMenu: false,
      openMenuId: '',
      menu: this.info.menu[0].subs,
      promo: this.$store.state.info.topPromoDesktop,
      fileCdn: this.$store.state.fileCdn,
      hb: false,
      searchField: '',
      langSelector: false,
      langs: [{ title: 'Türkçe', v: 'tr' }, { title: 'English', v: 'en' }]
    }
  },
  jsonld () {
    return {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      url: this.$store.state.baseUrl,
      logo: this.$store.state.baseUrl + '/logo.svg'
    }
  },
  mounted () {
    if (this.$route.params.search) {
      this.searchField = this.$route.params.search
    }
  },
  computed: {
    cart () {
      return this.$store.state.cart
    }
  },
  methods: {
    changeLocale: function (l) {
      this.$i18n.locale = l
      localStorage.setItem('lang', l)
      this.langSelector = false
      setTimeout(() => {
        window.location.href = '/' + l + '/'
      }, 100)
    },
    showLanguageSelector: function () {
      this.langSelector = !this.langSelector
    },
    goPath: function (path) {
      this.$router.push({ name: path })
    },
    makeSearch: function () {
      const s = this.searchField.trim()
      if (s.length > 1) {
        this.$router.push({ name: 'Search', params: { search: s } })
      }
    },
    setMenu: function (mId, c) {
      /*
      if (c > 0) {
        this.openMenuId = mId
        this.menu = this.info.menu[mId].subs
        this.showMenu()
      } else {
        this.hideMenu()
      }
      */
    },
    showMenu: function () {
      setTimeout(() => {
        this.subMenu = true
      }, 0)
    },
    hideMenu: function () {
      this.openMenuId = ''
      this.subMenu = false
    },
    getCart: function () {
      this.$store.dispatch('setCart', 10)
    }
  }
}
</script>

<style scoped lang="scss">
.languageSelector {
  width:120px;margin:auto;list-style:none;
  display:flex;
  gap:22px;
  li {
    margin-bottom:22px;
  }
  .activeButton {
    background:var(--color1);
  }
  button {
    width:100%;
    border:none;
    background:#555;
    color:white;
    padding:9px;
    border-radius:8px;
    transition: all 0.14s ease-in;
  }
  button:hover {
    background:var(--color2);
  }
}
.promo {
  height:50px;
  font-family:arial;font-weight:bold; color:#fff; text-align:center;font-size:30px;display:grid;place-items:center;
  background-size: cover !important;
}

.topMenu {
  display:flex;
  align-items: center;
  a {
    color: #acacac;
    font-size: 13px;
    text-transform: uppercase;
    margin-right: 20px;
  }
  a:hover {
    color: #fff;
  }
}
header {
  display: grid;
  grid-template-rows: 20px auto 20px;
  .row1 {
    font-size:13px;
    line-height: 30px;
    display: flex;
    justify-content: flex-end;
    place-items: center;
    div {
      height: 24px;
      border-right: 1px solid #e6e6e6;
      margin-top: 2px;
      a {
        height: 100%;
        line-height: 100%;
        display: flex;
        place-items: center;
        padding-left: 18px;
        padding-right: 18px;
        font-family: var(--font2);
        color: #888;
        font-weight: bold;
        i {
          margin-right: 5px;
        }
      }
      .prime {
        background:var(--color1);
        color: white;
        border-radius: 4px;
        padding-top: 4px;
        padding-bottom: 4px;
      }
      .prime:hover {
        background:orangered;
        color: white;
        span {
          text-decoration: none;
        }
      }
      a:hover {
        span {
          text-decoration: underline;
        }
        color: var(--color1);
      }
    }
    div:last-child {
      border: none;
      a {
        padding-right: 0px;
      }
    }
  }
  .row2 {
    display:grid;
    gap: 20px;
    grid-template-columns: 110px auto 660px;
    .cartZone {
      display:flex;
      align-items: center;
      justify-content: flex-end;
      gap: 28px;
      a {
        color: #acacac;
        font-size: 13px;
        text-transform: uppercase;
        .cartCount {
          background: red;
          display: block;
          width:16px;
          height:16px;
          border-radius: 50%;
          color: white;
          text-align: center;
          font-weight: bold;
          position:absolute;
          font-size: 10px;
          line-height: 16px;
          font-family:var(--font2);
          margin-left:8px;
          margin-top:-22px;
        }
      }
      a:hover {
        color:#fff;
      }
    }
  }
}
.searchContainer {
  display:grid;place-items:center;
  font-family: var(--font2);
  .searchBox2 {
    width:100%;
    input {
      width: calc(100% - 52px);
      border:none;
      height:36px;
      color: #fff;
      transition: 0.1s all ease-in;
      font-size: 12px;
      padding-left: 26px;
      background: url(../assets/search.svg) left no-repeat #c2c2c2;
      background-size: 15px 15px;
      background-position: 8px;
    }
    input:focus {
      background: url(../assets/search.svg) left no-repeat #fff;
      background-size: 15px 15px;
      background-position: 8px;
      box-shadow:unset;
      color:#333333
    }
    button {
      background:var(--gri2);
      border: none;
      height:36px;
      color:#fff;
      width:50px;
      font-size: 12px;
      transition: 0.1s all ease-in;
    }
  }
  .searchBox {
    width:100%;
    border:2px solid var(--gri2);
    border-radius:5px;
    overflow:hidden;
    height:42px;
    background: var(--gri2);
    input {
      padding:5px;
      padding-left:40px;
      border:none;
      width: calc(100% - 60px);
      background: url(../assets/search.svg) left no-repeat;
      background-size: 20px 20px;
      background-position: 12px;
      background-color:#fff !important;
      height:100%;
    }
    button {
      background:var(--gri2);
      color:#fff;
      width:60px;
      height:100%;
      border:none;
      transition: 0.1s all ease-in;
    }
    button:hover {
      filter: brightness(1.2);
    }
  }
}
.subMenu {
  font-family: var(--font2);
  background:#fff;
  position:absolute;
  width:1200px;
  z-index:3;
  padding:20px;
  margin-top: 0px;
}
.parent {
  display:grid;
  grid-template-columns:1fr 1fr 1fr 1fr;
}
ul.subs {
  list-style:none;
  li{
    margin-bottom: 20px;
    a {
      font-size: 14px;
      font-weight: bold;
      color:#666;
    }
    a:hover, .active {
      color:var(--color1);
    }
    ul {
      list-style: none;
      li {
        margin: 0;
        padding-top: 2px;
        padding-bottom: 2px;
        a {
          font-weight: normal;
        }
      }
    }
  }
}
.overlay {
  background:rgba(0,0,0,0.4);width:100%;height:1200px;position:absolute;z-index:2;
}
.fade-enter-active, .fade-leave-active {
  z-index: 4;
  position: absolute;
  width:100%;
  transition: opacity .1s ease-in;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  z-index: 4;
  position: absolute;
  width:100%
}
.logo {
  margin:auto;
  width: 100%;
  height: 52px;
  display: grid;
  align-items:center;
  background: url('../assets/logo2.svg') center no-repeat;
  background-size: contain;
}
nav {
  background:url(../assets/stripe.png) repeat-x;
  background-size:450px 3px;
  height: 50px;
  background-color:#f5f5f5;
  background-color:#fff;
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  box-shadow: 0px 2px 1px 0px rgba(0,0,0,0.05);
  user-select: none;
  ul {
    margin: auto;
    list-style: none;
    display: flex;
    justify-content: center;
    li {
      ul {
        width:200px;
      }
    }
  }
  a {
    color: #555;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    display: inline-block;
    height: 45px;
    line-height: 45px;
    margin-top: 4px;
    border-right: 1px solid #e7e7e7;
    transition: all 0.2s;
  }
  a:first-child {
    border-left: 1px solid #e7e7e7;
  }
  a:hover, .activeMenu {
    background:#fff;
    color: var(--color1);
  }
}

.sag {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}
.cart {
  border:none;
  mask: url('../assets/icons/cart.svg') no-repeat center;
  mask-size: cover;
  background-color: black;
  width: 20px;
  height: 20px;
}
.search {
  border:none;
  mask: url('../assets/icons/mag.svg') no-repeat center;
  mask-size: cover;
  background-color: black;
  width: 17px;
  height: 17px;
}
@media screen and (max-width: 768px) {
  nav {
    display:none
  }
}
</style>
