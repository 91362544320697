<template>
  <div class="container mb">
    <div class="gridBlock">
      <div class="outer" v-for="item in info.subs" :key="item.id">
        <div class="inner" :style="'background: url(' + cdn + item.source + ') center'">
          <router-link v-if="item.sUrl" :to="item.sUrl"></router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.gridBlock {
  display:grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  padding-top: 20px;
  padding-bottom: 20px;
  gap:15px;
}
.outer {
  border-radius: 8px;
  background:#ccc;
  height: 200px;
  overflow: hidden;
}
.inner{
  width: 100%;
  height: 100%;
  transition: 0.16s;
  display: grid;
  align-items: center;
  justify-items:center ;
  background-color: #000;
  background-size: cover !important;
  background-position:center !important;
  a {
    display: block;
    width: 100%;
    height: 100%;
  }
}
.inner:hover {
  background-size: cover;
  background-position: center;
  transform: scale(1.05)  translateY(0px);
}
@media screen and (max-width:768px) {
  .gridBlock {
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 20px;
  }
  .outer {
    height: 140px;
  }
}
@media screen and (min-width:768px) and (max-width:1000px) {
  .gridBlock {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media screen and (min-width:1000px) and (max-width:1200px) {
  .gridBlock {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}
</style>

<script>
export default {
  props: ['info'],
  data () {
    return {
      cdn: this.$store.state.fileCdn
    }
  }
}
</script>
