<template>
  <div class="container mb" style="overflow:auto; scroll-snap-type:x mandatory;">
    <div class="besli">
      <div class="itemContainer" v-for="item in info.subs" :key="item.id">
        <div class="item" :style="'background:url(' + cdn + '' + item.source + ');height:' + info.data.keywords">
          <router-link v-if="item.sUrl" :to="item.sUrl" :title="item.sUrlText"></router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.besli {
  display: flex;
  place-items:center;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  scroll-padding: 1rem;
  scroll-snap-type:x mandatory;
  width: 1200px;
  .itemContainer {
    scroll-snap-align: start;
    border-radius:8px;
    overflow:hidden;
    width:100%;
    margin-right: 20px;
  }
  .itemContainer:last-child {
    margin-right: 0px;
  }
  .item {
    background-size: cover !important;
    background-position: center !important;
    background-repeat: none;
    width: 100%;
    transition: all 0.1s ease-in;
    a {
      display: block;
      height: 100%;
    }
  }
}
</style>

<script>
export default {
  props: ['info'],
  data () {
    return {
      cdn: this.$store.state.fileCdn
    }
  }
}
</script>
