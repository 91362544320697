<template>
  <div class="blogs">
    <div class="blogList container">
      <BlogCard v-for="item in info" :key="item.id" :info="item" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.blogs {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  background:#f1f1f1;
  padding-top: 30px;
  padding-bottom: 30px;
}
.blogList {
  display:grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}
@media screen and (max-width:768px) {
  .blogList {
    grid-template-columns: 1fr;
  }
}
</style>

<script>
import BlogCard from '@/small-components/BlogCard'
export default {
  components: {
    BlogCard
  },
  props: ['info'],
  data () {
    return {
      home: 'yes Mofos so'
    }
  }
}
</script>
