<template>
  <div>
    <Header :info="info" />
    <Products />
    <Footer :info="info" />
  </div>
</template>

<script>
export default {
  props: ['info']
}
</script>
