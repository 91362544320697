<template>
  <div class="imageContainer">
    <div @keydown.esc="bigImageClose">
      <splide
      @splide:move="move"
      ref="splide" :options="options" class="spld">
        <splide-slide v-for="(item, idx) in product.images" :key="idx">
          <img class="bigImage" oncontextmenu="return false" style="cursor:zoom-in;" width="100%"
          @click="bigImage"
          :alt="product.title"
          :src="mediaCdn + '/1200_' + item" />
        </splide-slide>
      </splide>
      <div class="thumbs">
        <img v-for="(item, idx) in product.images" :key="idx"
          oncontextmenu="return false"
          :class="{ 'active': selectedIndex === idx}"
          @click="selectImage(idx)"
          :alt="product.title"
          :src="mediaCdn + '/64_' + item" />
      </div>
    </div>
    <div class="imageZoomer" :class="{ 'visible': imageZoomer }">
      <div @keydown.esc="bigImageClose">
        <splide
        @splide:moved="move2"
        ref="splide2" :options="options2" class="spld2">
          <splide-slide v-for="(item, idx) in product.images" :key="idx">
            <img class="bigimages" oncontextmenu="return false" style="width:770px;"
            :alt="product.title"
            :src="mediaCdn + '/1200_' + item" />
          </splide-slide>
        </splide>
      </div>
    </div>
    <div v-if="imageZoomer" @click="bigImageClose" class="overlay" :style="'height:' + ($fnc.getHeight() - 200) + 'px'"></div>
  </div>
</template>

<script>
export default {
  props: ['product'],
  data: function () {
    return {
      selectedIndex: 0,
      mediaCdn: this.$store.state.mediaCdn,
      imageZoomer: false,
      options: {
        rewind: false,
        type: 'slide',
        width: 440,
        arrows: true,
        pagination: false,
        keyboard: false,
        autoplay: false,
        breakpoints: {
          768: {
            width: this.$fnc.getWidth() - 40
          }
        }
      },
      options2: {
        rewind: false,
        type: 'slide',
        width: 850,
        arrows: true,
        pagination: false,
        keyboard: true,
        breakpoints: {
          768: {
            width: this.$fnc.getWidth() - 40
          }
        }
      }
    }
  },
  beforeMount () {
    if (!this.product.images) {
      this.product.images = ['no-image.png']
    }
  },
  mounted () {
    this.setImageSize()
    const hash = (this.$route.hash)
    if (hash === '#bigImage') {
      this.$router.push('')
    }
    const imageCount = this.product.images.length
    const splide = this.$refs.splide.splide
    const splide2 = this.$refs.splide2.splide
    if (imageCount < 2) {
      splide.options = { arrows: false }
      splide.destroy()
      splide.mount()
      splide2.options = { arrows: false }
      splide2.destroy()
      splide2.mount()
    }
  },
  watch: {
    $route (to, from) {
      const hash = to.hash
      if (hash === '#bigImage') {
        if (!this.imageZoomer) {
          this.imageZoomer = true
        }
      } else {
        if (this.imageZoomer) {
          this.imageZoomer = false
        }
      }
    }
  },
  methods: {
    setImageSize: function () {
      var e = this.$fnc.getWidth() - 40
      if (e < 808) {
        document.querySelector('.spld').style.height = e + 'px'
      }
    },
    move: function (splide, index) {
      this.selectedIndex = index
      this.selectImage2(index)
    },
    move2: function (splide, index) {
      this.selectedIndex = index
      this.selectImage(index)
    },
    bigImage: function () {
      this.imageZoomer = true
      if (this.$fnc.getWidth() < this.$store.state.mobileWidth) {
        const bi = document.querySelectorAll('.bigimages')
        bi.forEach(b => {
          b.style.width = this.$fnc.getWidth() - 40 + 'px'
        })
      }
      setTimeout(() => {
        this.selectImage2(this.selectedIndex)
        this.$router.push('#bigImage')
      }, 10)
    },
    bigImageClose: function () {
      if (this.imageZoomer) {
        this.$router.go(-1)
        this.imageZoomer = false
      }
    },
    selectImage: function (idx) {
      this.selectedIndex = idx
      this.$refs.splide.splide.go(idx)
    },
    selectImage2: function (idx) {
      this.$refs.splide2.splide.go(idx)
    }
  }
}
</script>

<style scoped lang="scss">
.imageZoomer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 13;
  display: none;
  width: 770px;
  height: calc(100vh - 60px);
  margin-top: 30px;
  margin-left: calc((100vw - 770px) / 2);
  div {
    width: 100%;
    height: 100%;
  }
}
.visible {
  display: block;
}
@media screen and (max-width:768px) {
  .imageZoomer {
    width: calc(100vw - 40px);
    height: calc(100vh - 320px);
    margin-left: 20px;
    margin-top: 120px;
  }
}
.overlay {
  background:rgba(0,0,0,0.4);width:100%;height:1200px;position:absolute;z-index:12;
  top:0;
  left: 0;
}
.spld {
  height: 440px;
  background-color:#fff;
  border:2px solid #ebebeb;
  border-radius:0px;
  overflow:hidden;
  margin-bottom:12px;
}
.spld2 {
  height: 100%;
  width: 100%;
  background:#fff;
  border-radius: 0px;
  overflow: hidden;
  display: flex;
  place-items: center;
}
.thumbs {
  display:flex;
  gap: 12px;
  justify-content: left;
  height: 64px;
  img {
    border:2px solid var(--white);
    border-radius: 0px;
    cursor: pointer;
    transition: all 0.1s ease-in;
    user-select: none;
    -webkit-user-drag: none;
    width: 64px;
    height: 64px;
  }
  .active, .active:hover {
    border:2px solid var(--color1);
  }
}
</style>
