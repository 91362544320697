<template>
  <div class="hero" v-if="info.show" :style="'background:url(' + info.src + ') no-repeat;'">
    <div>
      <h1 :style="'color:' + info.color">{{ info.title }}</h1>
      <p :style="'color:' + info.color" v-if="info.text">{{ info.text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['info']
}
</script>

<style scoped lang="scss">
.hero {
  background-size: cover !important;
  background-position: bottom !important;
  background-attachment: fixed !important;
  min-height: 600px;
  display: grid;
  place-items: center;
  div {
    width:640px;
    margin:auto;
    display: grid;
    place-items: center;
    height: 140px;
    h1 {
      font-size: clamp(1.4rem, -0.3778rem + 3.2407vw, 2.4rem);
      letter-spacing: 7px;
      text-transform: uppercase;
      color: #dddddd;
      text-align: center;
      font-weight: normal;
      // text-shadow: 1px 1px 0px rgba(0,0,0,0.4);
    }
    p {
      text-align: center;
      line-height: clamp(1.5rem, -0.7778rem + 3.0093vw, 2rem);
      color: #afafaf;
      font-size: clamp(0.9rem, 0.5444rem + 0.6019vw, 1.1rem);
      // text-shadow: 1px 1px 0px rgba(0,0,0,0.4);
    }
  }
}
@media screen and (max-width: 768px) {
  .hero {
    min-height: 200px;
    background-size: cover !important;
    background-position: center !important;
    div {
      width: calc(100% - 40px);
    }
  }
}
</style>
