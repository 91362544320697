<template>
  <div style="padding-bottom:60px" class="navContainer">
    <nav>
      <router-link :to="{ name: 'Index' }">
        <div class="icon home">
          <i class="fa fa-home"></i>
        </div>
        <span>{{ $t('Anasayfa') }}</span>
      </router-link>
      <router-link v-for="item in info.menu" :key="item.id" :to="{ name: 'Products', params: { id: item.id + '-' + $fnc.seo(item.title), top: item.id }}">
        <div class="icon profile">
          <i class="fa fa-list"></i>
        </div>
        <span>{{ $t('Ürünler') }}</span>
      </router-link>
      <router-link :to="{ name: 'Login' }" v-if="!$store.state.user.userId">
        <div class="icon profile">
          <i class="fa fa-user"></i>
        </div>
        <span>{{ $t('Üye Ol / Giriş Yap') }}</span>
      </router-link>
      <router-link :to="{ name: 'MyAccount' }" v-else>
        <div class="icon profile">
          <i class="fa fa-user"></i>
        </div>
        <span>{{ $t('Hesabım') }}</span>
      </router-link>
      <router-link :to="{ name: 'Cart' }">
        <div class="icon cart">
          <i class="fa fa-shopping-cart"></i>
          <span class="cartCount" v-if="$store.state.cart > 0">{{ $store.state.cart }}</span>
        </div>
        <span>{{ $t('Sepetim') }}</span>
      </router-link>
    </nav>
    <div id="mobileMenu" class="mobileMenu">
      <ul id="mobileMenuTop">
        <li class="lvl1" v-for="(item, idx) in info.menu" :key="item.id">
          <a v-if="item.subs.length > 0"
            :class="{ 'activeMenu': openMenuId === idx}"
            @click="openSubMenu(idx)">{{ item.title }} <i v-if="item.subs.length > 0" class="fa fa-angle-right"></i>
          </a>
          <router-link v-else
            :to="{ name: 'Products', params: { id: item.id + '-' + $fnc.seo(item.title), top: item.id }}">{{ item.title }}
          </router-link>
        </li>
      </ul>
      <div>
        <div id="mobileMenuSubs" class="subs">
          <a v-if="subMenuIndex !== false" class="goBack" @click="closeSubMenu"><i class="fa fa-angle-left"></i>Tüm Kategoriler</a>
          <div style="margin-top:12px" :class="'divs' + item.sutun" v-for="(item) in menu" :key="item.id">
            <ul v-for="sItem in item.subs" :key="sItem.id">
              <li style="margin-bottom:20px">
                <router-link style="padding:0"
                  :to="{ name: 'Products', params: { id: sItem.id + '-' + $fnc.seo(sItem.title), top: item.parent_id }}"><b>{{ sItem.title }}</b>
                </router-link>
                <ul>
                  <li class="lvl2" v-for="sItem2 in sItem.subs" :key="sItem2.id">
                    <router-link v-if="!sItem2.url"
                      :to="{ name: 'Products', params: { id: sItem2.id + '-' + $fnc.seo(sItem2.title), top: item.parent_id }}">{{ sItem2.title }}
                    </router-link>
                    <router-link v-else :to="sItem2.url">
                    {{ sItem2.title }}
                    </router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div v-if="overlay" id="footerOverlay" @mouseover="closeMenu" class="overlay" :style="'height:' + ($fnc.getHeight() - 200) + 'px;'"></div>
  </div>
</template>

<script>
export default ({
  props: ['info'],
  methods: {
    openSubMenu: function (idx) {
      this.subMenuIndex = idx
      this.menu = this.info.menu[idx].subs
      document.querySelector('#mobileMenuSubs').style.left = '20px'
      document.querySelector('#mobileMenuTop').style.left = '-800px'
    },
    closeSubMenu: function () {
      this.subMenuIndex = false
      this.menu = false
      document.querySelector('#mobileMenuSubs').style.left = '800px'
      document.querySelector('#mobileMenuTop').style.left = '20px'
    },
    openMenu: function () {
      this.$store.dispatch('setBottomMenu', true)
    },
    closeMenu: function () {
      this.$store.dispatch('setBottomMenu', false)
    },
    pOpenMenu: function () {
      this.overlay = true
      document.querySelector('body').style.overflow = 'hidden'
      document.querySelector('#mobileMenu').style.bottom = 0
      setTimeout(() => {
        document.querySelector('#footerOverlay').style.opacity = 1
      }, 10)
    },
    pCloseMenu: function () {
      document.querySelector('#footerOverlay').style.opacity = 0
      setTimeout(() => {
        // this.menuOpened = false
        this.overlay = false
        document.querySelector('body').style.overflow = 'auto'
        document.querySelector('#mobileMenu').style.bottom = 'calc((60vh) * -1)'
      }, 60)
    }
  },
  data () {
    return {
      // menuOpened: this.$store.state.bottomMenu,
      overlay: false,
      openMenuId: '',
      subMenuIndex: false,
      menu: false
    }
  },
  computed: {
    menuOpened () {
      return this.$store.state.bottomMenu
    }
  },
  watch: {
    menuOpened () {
      if (this.menuOpened) {
        this.pOpenMenu()
      } else {
        this.pCloseMenu()
      }
    },
    $route (to, from) {
      if (this.overlay) {
        setTimeout(() => {
          this.closeMenu()
          setTimeout(() => {
            // this.closeSubMenu()
          }, 200)
        }, 200)
      }
    }
  }
})
</script>

<style scoped lang="scss">
.goBack {
  position: sticky;
  top: 0;
  display: grid;
  grid-template-columns: 20px auto;
  align-items:center;
  font-weight:bold;
  background:var(--color2);
  color:white;
  font-size: 18px;
  padding-left:12px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: calc(100vw) !important;
  margin-left: -20px;
}
#mobileMenuTop {
  position: absolute;
  left: 20px;
  width: calc(100vw - 40px);
  top: 20px;
  transition:all 0.2s;
}
.subs {
  position: absolute;
  left: 100vw;
  width: calc(100vw - 40px);
  transition:all 0.2s;
}
.lvl1 {
  border-bottom: 1px solid #ccc;
}
.lvl1:last-child {
  border: none;
}
.lvl2 {
  padding-left: 12px;
  border-bottom: 1px solid #ccc;
}
.lvl2:last-child {
  border: none;
}
.mobileMenu {
  position:fixed;
  bottom: calc((60vh) * -1);
  left: 0;
  width: calc(100vw);
  overflow: hidden;
  height: calc(60vh);
  background: #fff;
  border-radius: 14px 14px 0 0;
  z-index: 20;
  transition: all 0.2s ease-out;
  overflow-y: scroll;
}
ul {
  list-style: none;
  li {
    a {
      font-size: 18px;
      padding-top: 8px;
      padding-bottom: 8px;
      display: grid;
      grid-template-columns: auto 20px;
      color: #666;
    }
    .active {
      color: var(--color1);
    }
  }
}
.overlay {
  background:rgba(0,0,0,0.7);width:100%;height:100%;position:absolute;z-index:12; top:0;
  overscroll-behavior: contain;
  transition: all 0.2s;
  opacity: 0;
}
.cartCount {
  margin-left:17px;
  margin-top:-38px;
  background:var(--color1);
  color:white;
  border:2px solid #fff;
  text-align:center;
  font-weight:bold;
  display:grid;place-items:center;
  width:18px;
  height:18px;
  border-radius:50%;
  font-size: 8px;
}
nav {
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 52px;
  padding-bottom: calc(env(safe-area-inset-bottom) + 30px);
  background: #fff;
  border-top: 1px solid #ccc;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  place-items: center;
  z-index: 10;
  a {
    text-align: center;
    font-size: 10px;
    color: #747474;
    width:100%;
    height:100%;
    .icon {
      width: 44px;
      height: 20px;
      display: grid;
      place-items: center;
      margin: auto;
      margin-top: 9px;
      i {
        font-size: 20px;
      }
    }
    span {
      margin-top: -10px;
    }
  }
  .active {
    color: var(--color1);
  }
}
@media screen and (max-height:540px ) {
  .navContainer {
    display:none;
  }
}
</style>
