<template>
  <div>
    <div v-if="summary">
      <div class="summaryTable">
        <div class="tRow" style="border-bottom:1px solid #ccc;padding-top:12px;padding-bottom:12px;margin-bottom:12px">
          <div class="tLabel" style="font-size:14px;padding-top:8px">{{ $t('Ödenecek Tutar') }}</div>
          <div class="tPrice" style="font-size:24px">{{ (summary.totalPrice + summary.shipment).toFixed(2) }} ₺</div>
        </div>
        <div class="tRow"><div class="tLabel">{{ $t('Ara Toplam') }}</div><div class="tPrice">{{ summary.base.toFixed(2) }} ₺</div></div>
        <div v-if="summary.vat1" class="tRow"><div class="tLabel">{{ $t('KDV') }} (%1)</div><div class="tPrice">{{ summary.vat1.toFixed(2) }} ₺</div></div>
        <div v-if="summary.vat8" class="tRow"><div class="tLabel">{{ $t('KDV') }} (%8)</div><div class="tPrice">{{ summary.vat8.toFixed(2) }} ₺</div></div>
        <div v-if="summary.vat18" class="tRow"><div class="tLabel">{{ $t('KDV') }} (%18)</div><div class="tPrice">{{ summary.vat18.toFixed(2) }} ₺</div></div>
        <div v-if="summary.vat20" class="tRow"><div class="tLabel">{{ $t('KDV') }} (%20)</div><div class="tPrice">{{ summary.vat20.toFixed(2) }} ₺</div></div>
        <div v-if="summary.shipment" class="tRow"><div class="tLabel">{{ $t('Kargo Ücreti') }}</div><div class="tPrice">{{ summary.shipment.toFixed(2) }} ₺</div></div>
        <div v-else class="tRow"><div class="tLabel" style="color:green;font-weight:bold">{{ $t('Kargo Ücreti') }}</div><div class="tPrice" style="color:green;font-weight:bold">{{ $t('BEDAVA') }}</div></div>
      </div>
      <div class="buttonSet" v-if="mode === 'cart'">
        <router-link :to="{ name: 'CompleteOrder' }" class="completeBtn">{{ $t('Alışverişi Tamamla') }} <i class="fa fa-arrow-circle-right"></i> </router-link>
      </div>
      <div class="buttonSet" v-if="mode === 'completeOrder'">
        <router-link :to="{ name: 'Cart' }" class="completeBtn gri"><i class="fa fa-arrow-circle-left"></i> {{ $t('Sepeti Düzenle') }}</router-link>
      </div>
      <div class="warnings">
        <div style="border-color:green" v-if="summary.shipmentTo > 0"><b>{{ summary.shipmentTo.toFixed(2) }} ₺</b> daha alışveriş yaparsanız kargo bedava</div>
      </div>
    </div>
    <div v-else style="display:grid;place-items:center;">
      <img src="@/assets/loading-pulse.svg">
    </div>
  </div>
</template>

<script>
export default ({
  props: ['summary', 'mode']
})
</script>

<style scoped lang="scss">
.buttonSet {
  display:grid;
  place-items:center;
  padding-top:6px;
  padding-bottom:6px;
  margin-top:6px;
  margin-bottom:6px;
}
</style>
