<template>
  <div class="container" style="margin-bottom:50px;">
    <div class="cart" v-if="items.length > 0">
      <div class="contents">
        <CartItems v-if="mode === 'cart'" :items="items" />
        <CompleteOrder v-if="mode === 'completeOrder'" :paymentOptions="paymentOptions" />
      </div>
      <div class="summary">
        <CartSummary :mode="mode" :summary="summary" />
      </div>
    </div>
    <div class="cart" v-else style="grid-template-columns:1fr;min-height:350px;background:#fff;">
      <div class="contents" style="text-align:center" v-if="!isLoading">{{ $t('Sepetinizde hiç ürün yok') }}</div>
      <div class="contents" style="text-align:center" v-else><img src="@/assets/loading-pulse.svg"></div>
    </div>
  </div>
</template>

<script>
import CartSummary from '@/small-components/CartSummary'
import CartItems from '@/small-components/CartItems'
import CompleteOrder from '@/small-components/CompleteOrder'
import axios from 'axios'

export default {
  components: { CartSummary, CartItems, CompleteOrder },
  props: ['mode'],
  data: function () {
    return {
      isLoading: true,
      items: [],
      attempts: 0,
      summary: null,
      paymentOptions: [],
      selectedPayment: null,
      uniq: null
    }
  },
  metaInfo () {
    let title = this.$i18n.t('Sepetim')
    if (this.mode === 'completeOrder') {
      title = this.$i18n.t('Alışverişi Tamamla')
    }
    return {
      title: title,
      meta: [{ vmid: 'description', name: 'description', content: '' }, { vmid: 'robots', name: 'description', content: 'noindex' }]
    }
  },
  beforeMount () {
    this.getCart()
  },
  methods: {
    deleteFromCart: async function (id) {
      var r = confirm('Ürünü sepeteinizden çıkarmak istediğinize emin misiniz?')
      if (!r) {
        return
      }
      this.isLoading = true
      const payload = { user: this.$store.state.user, id: id }
      await axios.post('/api/deleteFromCart.php', payload).then(response => {
        const err = response.data.error
        console.log(err)
        if (err === 'none') {
          this.getCart()
        } else {
          this.$toast.error(err)
        }
        this.isLoading = false
        this.$store.dispatch('setCart', response.data.cartCount)
        this.attempts = 0
      }).catch(error => {
        this.$toast.error(error)
        this.isLoading = false
      })
    },
    getCart: async function () {
      const payload = { user: this.$store.state.user }
      await axios.post('/api/getCart.php', payload).then(response => {
        const data = response.data
        this.items = data.items
        this.isLoading = false
        this.attempts = 0
        this.summary = data.summary
        this.paymentOptions = data.paymentOptions
        this.selectedPayment = data.paymentOptions[0]
        this.uniq = data.uniq
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/css/cart.css';
</style>
